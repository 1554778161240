import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M6165 11544 c-16 -2 -79 -9 -140 -15 -123 -11 -380 -51 -495 -75
-136 -29 -375 -101 -495 -149 -33 -13 -104 -41 -157 -62 -58 -22 -98 -44 -98
-52 0 -14 31 -12 74 5 15 6 18 4 14 -7 -21 -51 -22 -49 15 -49 31 0 36 -3 34
-22 -1 -22 -33 -57 -51 -58 -24 -1 -357 -172 -456 -235 -157 -99 -246 -157
-310 -205 -41 -30 -93 -67 -115 -82 -35 -24 -65 -49 -205 -170 -144 -125 -322
-305 -438 -445 -32 -38 -59 -52 -59 -29 0 6 -1 20 -2 31 -1 16 -3 17 -10 6
-12 -19 -41 -23 -47 -6 -3 7 6 31 20 54 25 39 34 81 18 81 -28 0 -134 -137
-295 -380 -122 -183 -140 -216 -245 -425 -264 -528 -418 -1241 -397 -1840 18
-526 77 -872 222 -1295 65 -189 198 -497 270 -622 18 -32 49 -86 68 -119 99
-173 313 -478 434 -619 104 -122 296 -312 416 -412 66 -55 131 -110 145 -122
66 -56 277 -202 380 -262 193 -112 416 -220 570 -275 402 -146 733 -220 1054
-238 177 -9 254 -6 354 15 66 14 215 60 282 87 l25 10 -26 7 c-14 4 -93 8
-175 9 -167 2 -364 21 -529 52 -615 113 -1008 259 -1488 554 -77 46 -268 183
-356 253 -133 106 -407 381 -535 537 -109 132 -294 402 -380 552 -291 515
-448 1027 -506 1658 -13 141 -13 528 0 670 35 389 132 782 272 1100 330 753
770 1285 1423 1718 187 124 235 153 403 236 206 103 273 132 477 205 461 165
1026 247 1495 217 466 -30 813 -99 1194 -237 224 -82 360 -142 541 -241 369
-201 661 -419 960 -718 207 -206 375 -401 491 -569 37 -55 70 -94 72 -87 11
33 -110 337 -173 436 -94 147 -242 320 -436 512 -269 263 -518 444 -919 665
-125 69 -490 219 -671 276 -260 82 -629 150 -931 171 -134 10 -511 13 -583 5z
m-1245 -348 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0 5 9 9 20 9 11 0 20 -2
20 -4z m-1650 -1366 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z"/>
<path d="M6910 10013 c-16 -3 -89 -70 -210 -194 l-185 -189 -75 -2 c-41 -1
-84 -3 -95 -4 -11 -1 -337 -3 -725 -4 -456 -2 -684 -6 -645 -11 33 -5 98 -14
144 -20 54 -8 78 -14 70 -20 -10 -6 -8 -9 8 -9 19 0 22 -5 21 -40 0 -46 -20
-69 -43 -50 -18 15 -19 7 -4 -21 9 -17 4 -26 -30 -60 -22 -23 -46 -56 -53 -75
-9 -25 -17 -34 -29 -31 -12 3 -16 0 -12 -9 3 -9 0 -12 -8 -9 -19 7 -43 -10
-34 -25 4 -6 13 -9 21 -6 8 3 14 2 14 -3 0 -5 -17 -11 -37 -15 -41 -7 -75 -30
-86 -60 -6 -14 -14 -17 -36 -13 -19 4 -33 1 -41 -8 -7 -8 -22 -15 -34 -15 -12
0 -27 -5 -33 -11 -7 -7 -207 -12 -637 -16 -345 -4 -629 -9 -631 -13 -9 -14
573 -23 1080 -16 281 4 853 7 1272 7 l761 0 34 37 c18 21 56 65 83 97 107 127
214 244 315 346 l105 106 107 7 c121 8 591 6 683 -3 70 -7 64 -3 285 -217 74
-72 196 -189 269 -260 220 -211 280 -275 315 -333 37 -63 39 -70 66 -200 53
-249 70 -533 70 -1176 0 -376 -3 -491 -14 -532 -13 -47 -59 -146 -126 -268
-75 -138 -139 -263 -535 -1055 -452 -903 -500 -994 -548 -1050 -52 -60 -140
-131 -195 -157 -64 -30 -123 -28 -171 6 l-31 22 31 62 c18 34 52 98 77 142 52
93 107 197 337 650 92 182 214 420 270 530 57 110 176 344 265 520 153 302
213 417 230 440 4 6 26 45 49 88 l42 77 -1 453 c-1 583 -19 819 -77 977 -31
85 -117 203 -234 320 -116 117 -217 208 -278 250 l-35 24 -32 -39 c-29 -35
-145 -210 -505 -765 -103 -159 -259 -404 -324 -508 -27 -44 -90 -144 -140
-221 -49 -77 -121 -190 -160 -251 -68 -108 -150 -235 -343 -528 -51 -78 -97
-142 -101 -142 -4 0 -20 21 -37 47 -16 26 -73 115 -126 197 -53 83 -130 202
-170 265 -98 154 -194 302 -289 446 -43 66 -124 193 -181 283 -92 145 -266
414 -418 647 -30 46 -84 127 -120 179 l-65 96 -160 -3 -160 -2 -3 -1951 -2
-1951 91 -64 c183 -129 420 -289 430 -289 7 0 9 503 7 1489 -3 1403 -2 1489
14 1474 19 -17 205 -294 391 -584 69 -108 168 -260 219 -337 51 -77 158 -240
238 -363 289 -442 346 -524 366 -524 18 0 92 102 204 282 19 31 62 97 95 148
69 105 73 112 115 180 16 28 49 80 72 116 24 36 81 126 127 200 89 142 129
205 301 469 59 91 117 183 130 205 14 22 74 117 135 210 62 94 158 242 215
330 133 204 211 315 222 315 4 0 7 -386 6 -857 l-3 -857 -185 -117 c-102 -64
-196 -127 -209 -140 -13 -13 -100 -163 -193 -334 -94 -170 -209 -377 -256
-460 -80 -140 -218 -385 -350 -620 -30 -55 -81 -145 -113 -200 -32 -55 -65
-117 -75 -138 l-18 -38 90 -90 c112 -114 218 -202 281 -234 115 -57 272 -54
447 10 251 90 608 296 831 477 86 70 92 78 142 178 29 58 87 184 129 280 84
194 104 237 144 325 41 89 128 278 215 470 43 94 97 212 121 264 24 51 44 95
44 96 0 2 13 32 29 67 16 35 54 119 84 188 55 127 119 263 185 400 l37 75 5
385 c10 747 -11 1065 -91 1435 -44 198 -73 285 -136 405 -112 211 -275 403
-583 684 -36 33 -129 122 -207 198 l-143 138 -667 -1 c-368 -1 -679 -4 -693
-6z"/>
<path d="M3305 9980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3292 9930 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3315 9940 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4040 8660 c-58 -3 -155 -4 -216 -2 l-111 3 -7 -47 c-17 -125 6 -332
60 -552 45 -184 44 -162 44 -1415 l0 -1188 37 -35 c36 -33 117 -96 386 -301
66 -51 125 -89 130 -86 6 4 8 657 6 1820 l-4 1814 -110 -3 c-60 -2 -157 -5
-215 -8z m-127 -26 c31 -2 63 -6 70 -11 6 -4 24 -6 38 -5 22 3 27 -1 27 -22 0
-19 -6 -26 -19 -26 -30 0 -22 -20 11 -27 38 -7 48 -20 15 -20 -37 0 -51 -17
-54 -64 -3 -55 -6 -59 -47 -59 -25 0 -34 -4 -34 -16 0 -15 -8 -16 -57 -9 -32
4 -70 8 -85 9 -29 1 -40 16 -13 16 8 0 15 9 15 20 0 11 -4 20 -10 20 -5 0 -11
19 -11 43 -1 35 -2 37 -8 15 -4 -17 -10 -25 -17 -20 -12 7 -1 99 16 132 11 24
53 41 80 34 14 -4 51 -8 83 -10z"/>
<path d="M4918 2396 c-69 -19 -122 -58 -147 -108 -24 -51 -27 -123 -7 -171 20
-50 90 -103 165 -128 127 -42 171 -72 171 -119 0 -46 -23 -60 -95 -60 -69 0
-155 26 -198 60 -14 11 -29 20 -34 20 -12 0 -53 -85 -53 -110 0 -45 146 -110
265 -117 95 -6 146 6 211 50 84 56 116 140 89 233 -20 68 -57 97 -193 150 -54
21 -109 50 -124 65 -34 34 -37 74 -6 90 34 18 137 5 197 -25 l52 -26 29 47
c17 26 30 54 30 63 0 21 -93 76 -150 89 -60 14 -144 13 -202 -3z"/>
<path d="M2168 2393 c-23 -4 -28 -10 -28 -32 0 -16 -7 -171 -16 -346 -8 -175
-13 -324 -10 -332 4 -11 24 -13 83 -11 l78 3 6 70 c4 39 10 150 14 248 4 98
10 175 14 172 3 -4 38 -79 76 -168 l70 -162 84 0 84 0 86 169 86 170 6 -130
c4 -71 7 -180 8 -242 1 -97 3 -114 19 -120 19 -8 149 -8 157 0 8 8 -26 670
-36 696 -8 21 -14 22 -102 22 l-94 0 -99 -199 c-55 -110 -103 -200 -107 -200
-4 -1 -52 88 -105 197 l-97 197 -75 1 c-41 1 -87 -1 -102 -3z"/>
<path d="M3344 2393 c-6 -5 -28 -51 -49 -103 -21 -52 -84 -209 -141 -349 -57
-139 -101 -257 -98 -262 3 -5 41 -9 84 -9 90 0 89 -1 131 103 l23 57 155 -2
156 -3 23 -64 c12 -36 28 -71 34 -78 13 -16 178 -19 178 -3 0 18 -219 561
-258 640 l-37 75 -95 3 c-52 1 -100 -1 -106 -5z m167 -295 c27 -66 47 -123 44
-126 -3 -3 -52 -6 -108 -6 -120 -1 -115 -13 -57 144 21 58 41 114 45 125 6 19
7 19 16 2 6 -10 33 -72 60 -139z"/>
<path d="M3937 2393 c-4 -3 -7 -165 -7 -360 l0 -353 85 0 85 0 0 243 1 242 22
-27 c24 -29 151 -216 250 -368 l62 -95 85 3 c47 2 88 7 93 11 4 4 6 164 5 357
l-3 349 -82 3 -83 3 0 -216 c0 -124 -4 -215 -9 -215 -5 0 -53 62 -106 138
-226 322 -196 292 -303 292 -49 0 -92 -3 -95 -7z"/>
<path d="M5404 2386 c-3 -7 -4 -123 -2 -257 3 -243 3 -244 30 -295 31 -59 84
-108 147 -138 38 -17 65 -21 156 -21 98 0 114 3 150 24 76 45 114 82 142 138
27 56 27 61 31 275 3 119 1 233 -2 253 l-7 35 -84 0 -83 0 -4 -242 c-3 -272
-8 -291 -77 -324 -41 -20 -126 -14 -164 10 -47 31 -51 55 -57 311 l-5 240 -83
3 c-64 2 -84 0 -88 -12z"/>
<path d="M6179 2088 c0 -172 -3 -333 -6 -357 -6 -53 3 -61 77 -61 103 0 99 -4
102 129 l3 116 65 2 65 3 60 -120 60 -120 97 0 c54 0 98 2 98 5 0 2 -11 24
-23 47 -13 24 -43 77 -65 119 -23 42 -42 84 -42 92 0 9 18 30 40 48 59 47 83
105 78 184 -5 86 -41 139 -120 179 -64 32 -172 45 -375 46 l-113 0 -1 -312z
m400 145 c27 -23 38 -83 21 -123 -19 -46 -55 -60 -160 -60 l-90 0 0 100 0 100
104 0 c84 0 108 -3 125 -17z"/>
<path d="M7337 2393 c-10 -16 -20 -533 -12 -626 l7 -87 201 0 c220 0 280 10
371 64 64 37 129 115 149 176 21 64 22 180 3 244 -31 101 -116 180 -231 212
-69 19 -478 33 -488 17z m433 -154 c46 -17 63 -34 95 -98 23 -45 26 -62 21
-105 -9 -79 -53 -156 -105 -185 -38 -21 -57 -24 -159 -25 l-117 -1 0 210 0
209 25 8 c35 11 198 2 240 -13z"/>
<path d="M8413 2388 c-4 -7 -49 -121 -99 -253 -51 -132 -112 -288 -137 -347
-25 -58 -43 -109 -40 -112 4 -3 39 -6 79 -6 86 0 91 4 129 95 l28 65 160 -2
160 -3 24 -65 c14 -36 30 -68 36 -72 16 -10 167 -10 167 0 0 20 -63 192 -129
351 -89 213 -146 332 -168 349 -11 7 -51 12 -109 12 -63 0 -95 -4 -101 -12z
m173 -270 c30 -73 54 -139 54 -145 0 -10 -29 -13 -110 -13 -60 0 -110 3 -110
8 0 4 17 50 38 102 21 52 44 117 52 143 7 26 15 45 18 43 3 -3 29 -65 58 -138z"/>
<path d="M8902 2338 l3 -63 105 -5 105 -5 3 -292 2 -292 43 -3 c23 -2 63 -1
90 0 l47 4 0 288 0 287 93 9 92 9 5 40 c3 22 4 50 1 63 l-3 22 -295 0 -294 0
3 -62z"/>
<path d="M9771 2392 c-15 -5 -28 -19 -34 -37 -5 -17 -66 -171 -134 -343 -69
-173 -123 -319 -120 -325 3 -7 26 -12 58 -11 110 2 105 0 127 52 11 26 23 59
27 75 l7 27 160 0 159 0 34 -74 34 -74 75 -7 c41 -4 79 -4 84 -1 19 11 -153
461 -245 646 l-38 75 -85 2 c-47 1 -96 -2 -109 -5z m117 -194 c11 -29 38 -92
60 -140 22 -48 37 -89 34 -92 -5 -6 -222 -9 -222 -3 0 2 18 50 39 108 22 57
42 121 46 142 4 20 11 37 15 37 5 0 17 -24 28 -52z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
